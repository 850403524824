<template>
  <div class="app">
    <!-- 轮播图 -->
    <div class="app-content">
      <el-carousel
        height="38.4375rem"
        indicator-position="none"
        v-if="list.length > 0"
      >
        <el-carousel-item v-for="(item, index) in list" :key="index">
          <img
            class="msgPic"
            width="100%"
            height="100%"
            :src="imgUrl + item.fileUri"
            alt=""
          />
        </el-carousel-item>
      </el-carousel>
      <el-carousel height="38.4375rem" indicator-position="none" v-else>
        <el-carousel-item v-for="(item, index) in defList" :key="index">
          <img
            class="msgPic"
            width="100%"
            height="100%"
            :src="item.fileUri"
            alt=""
          />
        </el-carousel-item>
      </el-carousel>
    </div>

    <commonNavigation></commonNavigation>
    <!-- <div>这里是详情的内容</div> -->
    <NewsDetails></NewsDetails>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import commonNavigation from '../../layout/components/commonNavigation/index.vue';
import NewsDetails from '../../views/newsModule/NewsDetails';
import { slideshowList } from '@/api/index';
export default {
  components: {
    // NewsDetails,
    commonNavigation,
    // cephalicBlock,
    NewsDetails,
  },
  data() {
    return {
      defList: [
        {
          fileUri: require('../../assets/comm/gongsigaikuang@1x.png'),
        },
      ],
      status: 0,
      list: [],
      id: 0,
      imgUrl: '',
    };
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler(newId, oldId) {
        this.getList(newId.Carousel);
      },
    },
  },
  methods: {
    // 轮播图
    async getList(id) {
      const res = await slideshowList(id);
      if (res.data.code == 200) {
        if (res.data.data && res.data.data.length > 0) {
          this.list = res.data.data;
        }
      } else {
        // this.status = 1;
        // this.list = this.defList;
      }
    },
  },
  mounted() {
    this.imgUrl = process.env.VUE_APP_BASE_URL;
    // this.$watch('$route.query.id', (paramID) => {
    //   console.log('查看动态id', paramID);
    //   this.id = paramID;
    // });
  },
  computed: {
    // ...mapState['carouselList'],
    ...mapState(['carouselList']),
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.app {
  width: 100%;
  .app-content {
    width: 100%;
    .msgPic {
      width: 100%;
    }
  }
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 1.125rem;
  opacity: 0.75;
  line-height: 18.75rem;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
