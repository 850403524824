<template>
  <div class="app-content">
    <div class="muns_box">
      <!-- 左边导航 -->
      <div class="muns_Left">
        <img
          class="pic"
          src="../../../assets//allImage//companyProfile/icon_home@2x.png"
          alt=""
        />
        <router-link style="font-size: 0.9375rem" :to="{ path: '/' }"
          >首页</router-link
        >
        <span class="leftRnder">
          <i class="iconfont icon-xiangyoujiantou"></i>
          {{ $store.state.dynamicParentName.columnsName }}
        </span>
        <span class="leftRnder">
          <i
            class="iconfont icon-xiangyoujiantou"
            v-show="$store.state.secondLevel.columnsName"
          ></i>
          {{
            $store.state.statusTypes == 0
              ? $store.state.secondLevel.columnsName
              : currentNickname
          }}
        </span>
      </div>
      <!-- 右边导航 -->
      <div class="muns_Riget">
        <div
          class="right_son"
          :class="{
            icsStyle:
              $store.state.statusTypes == 0
                ? $store.state.secondLevel.id === obj.id
                : index === countent,
          }"
          @click="navRightFn(index, newsList, obj)"
          v-for="(obj, index) in newsList"
          :key="obj.id"
        >
          {{ obj.columnsName }}
        </div>
      </div>
    </div>
    <keep-alive>
      <router-view v-if="isActive"></router-view>
    </keep-alive>
  </div>
</template>

<script>
export default {
  data() {
    return {
      oneId: '', // 保存一级导航的id
      isActive: true, // 控制组件的激活状态
      queryParams: {
        pageNum: 1, //当前页数
        pageSize: 10, //每页10条
        columnId: '',
      },
      list: [],
      currentNickname: '', //当前昵称
      parentList: [],
      countent: JSON.parse(localStorage.getItem('comIndex')) || 0,
    };
  },
  watch: {
    '$route.query.id': {
      immediate: true,
      handler(newId, oldId) {
        this.oneId = newId;
      },
    },
  },
  computed: {
    newsList() {
      return (this.parentList = this.$store.state.commonNavigationList);
    },
  },
  activated() {
    // 组件被激活时触发，可以在此处重新创建组件或执行初始化逻辑
    // 例如，重新获取数据、重置状态等
    this.isActive = true;
  },
  deactivated() {
    // 组件被停用时触发，可以在此处进行组件的销毁或清理逻辑
    // 例如，取消订阅、清空数据等
    this.isActive = false;
  },
  methods: {
    async navRightFn(index, newsList, obj) {
      this.countent = index;
      this.queryParams.columnId = obj.id;
      this.$store.state.secondLevel = obj;
      this.$store.state.commonNavigationList = newsList;
      // this.countent = index;
      this.$store.state.secondLevel.id = obj.id;
      // 修改状态
      this.$store.commit('changeState', 0);
      if (obj.isRegular == 2) {
        this.$store.dispatch('companyDepartmentSetting');
      } else {
        // 公司概况
        this.$store.dispatch('companyProfileList', obj.id);
        // 新闻列表
        this.$store.dispatch('newsListFn', this.queryParams);
      }

      this.$router.push({
        path: '/layout/companyProfile',
        query: {
          id: obj.id,
          isDatePageviews: obj.isDatePageviews,
          columnPageType: obj.columnPageType,
          columnPageStyle: obj.columnPageStyle,
          isRegular: obj.isRegular,
          isSubStyle: obj.isSubStyle,
          paramID: obj.parentId,
          columnTheme: obj.columnTheme,
        },
      });

      localStorage.setItem('comIndex', JSON.stringify(this.countent));
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  color: #c1c1c1;
  text-decoration: none;
}
@import url('//at.alicdn.com/t/c/font_4068950_yjnrsrqxcw.css');
.app-content {
  width: 100%;
  height: 3.125rem;
  background-color: #4b4843;
  display: flex;
  justify-content: center;
  .muns_box {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .muns_Left {
      width: 25rem;
      height: 100%;
      align-items: center;
      display: flex;
      font-size: 0.9375rem;
      color: #c1c1c1;
      ::v-deep .el-breadcrumb__inner {
        color: white;
      }
      ::v-deep breadcrumb__inner.is-link:hover {
        color: #409eff;
        cursor: pointer;
      }
      .pic {
        width: 0.9375rem;
        vertical-align: middle;
        margin-right: 0.1875rem;
      }
      .leftRnder {
        font-size: 0.9375rem;
        margin-left: 0.3125rem;
        i {
          font-size: 0.9375rem;
        }
      }
    }
    .muns_Riget {
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 0.9375rem;
      color: #c1c1c1;
      padding-right: 1.875rem;
      justify-content: flex-end;
      .right_son {
        margin-left: 0.9375rem;
        cursor: pointer;
      }
      .icsStyle {
        color: #0aa4ee;
      }
    }
  }
}
</style>
